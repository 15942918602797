import responsiveSizes from './responsive-sizes';

const theme = {
  colors: {
    blue: '#3465d2',
    lightBlue: '#477df4',
    darkGrey: '#252830',
    grey: '#7b8293',
    lightGrey: '#d2d6e0',
  },
  contentWidth: '1200px',
  responsiveSizes: {
    desktop: `${responsiveSizes.desktop}px`,
    tablet: `${responsiveSizes.tablet}px`,
    phone: `${responsiveSizes.phone}px`,
  },
};

export default theme;
