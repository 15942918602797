import React from 'react';
import styled, { keyframes } from 'styled-components';

import InstallButton from '../components/InstallButton';
import inBrowserDemoImage from '../images/in-browser-demo.svg';
import inAppDemoImage from '../images/in-app-demo.svg';
import { media } from '../style/style-utils';
import { H1 } from './Headings';

const Wrapper = styled.div`
  min-height: 700px;
  max-width: ${props => props.theme.contentWidth};
  padding: 15px;
  margin: auto;
`;

const fadeinLeftWrapper = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const LeftWrapper = styled.div`
  animation: ${fadeinLeftWrapper} 0.3s linear;
  display: inline-block;
  width: 50%;

  ${media.tablet`
    width: 100%;
    text-align: center;
  `}
`;

const RightWrapper = styled.div`
  display: inline-block;
  float: right;
  width: 50%;

  ${media.tablet`
    width: 100%;
    float: none;
  `}
`;

const fadeinBrowserDemoImage = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const BrowserDemoImage = styled.div`
  display: inline-block;
  width: 100%;
  height: 0;
  padding-top: 100%;
  margin: 20% 0 0 0;
  position: relative;
  animation: ${fadeinBrowserDemoImage} 0.5s linear;
  background-image: url(${inBrowserDemoImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  ${media.tablet`
    margin: 50px 0 0 0;
    background-position: center;
  `}
`;

const AppDemoImage = styled.div`
  display: inline-block;
  position: absolute;
  top: 17%;
  right: 4%;
  width: 54%;
  height: 0;
  padding-top: 80%;
  animation: ${fadeinBrowserDemoImage} 0.6s linear;
  background-image: url(${inAppDemoImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  border-radius: 4px;
`;

const InstallButtonWrapper = styled.div`
  display: flex;

  ${media.tablet`
    justify-content: center;
  `}
`;

const StyledH1 = styled(H1)`
  margin: 30% 0 20px 0;

  ${media.tablet`
    margin: 15% 0 20px 0;
    font-size: 2.7em;
  `}
`;

const StyledP = styled.p`
  font-family: "Montserrat";
  font-size: 1.9em;
  font-weight: 500;
  margin: 0 0 20px 0;

  ${media.tablet`
    margin: 0 0 20px 0;
    font-size: 1.6em;
  `}

  ${media.phone`
    font-size: 1.3em;
  `}
`;

const HeroSection = () => (
  <Wrapper>

    <LeftWrapper>
      <StyledH1>
        Grow your Instagram followers.
      </StyledH1>
      <StyledP>
        Get <b>real</b> and <b>engaged</b> followers.
      </StyledP>
      <InstallButtonWrapper>
        <InstallButton>INSTALL FOR FREE</InstallButton>
      </InstallButtonWrapper>
    </LeftWrapper>

    <RightWrapper>
      <BrowserDemoImage>
        <AppDemoImage/>
      </BrowserDemoImage>
    </RightWrapper>

  </Wrapper>
);

export default HeroSection;
