import styled, { css } from 'styled-components';
import { media } from '../style/style-utils';

const HeadingsStyle = css`
  font-family: "Montserrat";
`;

export const H1 = styled.h1`
  ${HeadingsStyle}

  font-size: 3em;
  font-weight: 700;
  margin: 20px 0;

  ${media.tablet`
    font-size: 2.7em;
  `}

  ${media.phone`
    font-size: 2.4em;
  `}
`;

export const H2 = styled.h2`
  ${HeadingsStyle}

  font-size: 2.9em;
  font-weight: 500;
  margin: 20px 0;

  ${media.tablet`
    font-size: 2.5em;
  `}

  ${media.phone`
    font-size: 2.2em;
  `}
`;

export const H3 = styled.h2`
  ${HeadingsStyle}

  font-size: 2em;
  font-weight: 400;
  margin: 20px 0;

  ${media.tablet`
    font-size: 1.8em;
  `}

  ${media.phone`
    font-size: 1.6em;
  `}
`;
