import { css } from 'styled-components';
import MontserratMedium from '../fonts/Montserrat-Medium.ttf';
import MontserratBold from '../fonts/Montserrat-Bold.ttf';
import MontserratRegular from '../fonts/Montserrat-Regular.ttf';

const fonts = css`
  @font-face {
    font-family: 'Montserrat';
    src: url('${MontserratRegular}');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('${MontserratMedium}');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('${MontserratBold}');
    font-weight: 700;
  }
`;

export default fonts;
