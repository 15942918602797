import { createGlobalStyle } from 'styled-components';
import fonts from './fonts';

const GlobalStyle = createGlobalStyle`
  ${fonts};

  html {
    box-sizing: border-box;
    font-size: 1em;
    margin: 0;
    padding: 0;
  }

  *, *::before, *::after {
		box-sizing: inherit;
	}

  body {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;
