import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { media } from '../style/style-utils';

const StretchedWrapper = styled.div`
  width: 100%;
  height: 70px;
  background-color: white;
  border: solid 1px rgba(123, 130, 147, 0.2);
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: ${props => props.theme.contentWidth};
  padding: 5px 15px;
  margin: auto;
  text-align: center;
`;

const FooterTextStyle = css`
  font-family: "Montserrat";
  text-decoration: none;
  color: ${props => props.theme.colors.grey};
  margin: 0 15px;
  font-family: 1em;

  ${media.phone`
    font-size: 0.7em;
    margin: 0 5px;
  `}
`;

const StyledLink = styled(Link)`
  ${FooterTextStyle}
`;

const Footer = () => (
  <StretchedWrapper>
    <ContentWrapper>
      <StyledLink to="infos">Infos</StyledLink>
      <StyledLink to="terms-of-service">Terms of service</StyledLink>
      <StyledLink to="privacy-policy">Privacy policy</StyledLink>
    </ContentWrapper>
  </StretchedWrapper>
);

export default Footer;
