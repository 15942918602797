import React from 'react';

import Layout from '../components/layout';
import HeroSection from '../components/HeroSection';
import TutoSection from '../components/TutoSection';

const IndexPage = () => (
  <Layout>
    <HeroSection/>
    <TutoSection/>
  </Layout>
);

export default IndexPage;
