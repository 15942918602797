import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { media } from '../style/style-utils';

import astralikerTextLogo from '../images/astraliker-text-logo.svg';
import astralikerLogo from '../images/astraliker-logo.svg';
import BorderButton from './BorderButton';
import InstallButton from './InstallButton';

const StyledBorderButton = styled(BorderButton)`
  margin-right: 10px;

  ${media.phone`
    margin-right: 0;
  `}
`;

const HideOnMobile = styled.span`
  ${media.phone`
    display: none;
  `}
`;

const FixedPlaceholder = styled.div`
  width: 100%;
  height: 70px;
`;

const StretchedWrapper = styled.div`
  z-index: 1;
  position:fixed;
  width: 100%;
  height: 70px;
  background-color: white;
  box-shadow: 0px 1px 4px 0px rgba(123, 130, 147, 0.2);
`;

const ContentWrapper = styled.div`
  height: 100%;
  max-width: ${props => props.theme.contentWidth};
  padding: 5px 15px;
  margin: auto;
`;

const AstralikerLogo = styled.div`
  display: inline-block;
  width: 40%;
  height: 50px;
  margin-top: 5px;
  background-image: url(${astralikerTextLogo});
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  cursor: pointer;

  ${media.phone`
    height: 40px;
    margin-top: 10px;
    background-image: url(${astralikerLogo});
  `}
`;

const RightContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
  height: 100%;
  float: right;
`;

const Header = () => (
  <FixedPlaceholder>
    <StretchedWrapper>
      <ContentWrapper>
        <AstralikerLogo onClick={() => { navigate("/"); }} role="link"/>
        <RightContentWrapper>
          <StyledBorderButton to="/infos">INFOS</StyledBorderButton>
          <HideOnMobile>
            <InstallButton>INSTALL FOR FREE</InstallButton>
          </HideOnMobile>
        </RightContentWrapper>
      </ContentWrapper>
    </StretchedWrapper>
  </FixedPlaceholder>
);

export default Header;
