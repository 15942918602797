import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const BorderButton = styled(Link)`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0px 20px;
  text-decoration: none;
  font-family: "Montserrat";
  transition: box-shadow 0.4s linear, border 0.4s linear;
  color: ${props => props.theme.colors.blue};
  border: 3px solid ${props => props.theme.colors.blue};
  border-radius: 15px;

  &:hover {
    color: ${props => props.theme.colors.lightBlue};
    border: 3px solid ${props => props.theme.colors.lightBlue};
    box-shadow: 0px 0px 4px 0px ${props => props.theme.colors.lightBlue};
  }
`;

export default BorderButton;
