import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import googleChromeIcon from '../images/google-chrome-icon.svg';

const ALink = styled.a`
  display: flex;
  align-items: center;
  min-height: 50px;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 15px;
  color: white;
  background-color: ${props => props.theme.colors.blue};
  transition: box-shadow 0.4s linear, background-color 0.4s linear;

  &:hover {
    background-color: ${props => props.theme.colors.lightBlue};
    box-shadow: 0px 0px 4px 0px ${props => props.theme.colors.lightBlue};
  }
`;

const ButtonIcon = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
  background-image: url(${googleChromeIcon});
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
`;

const ButtonText = styled.span`
  display: inline-block;
`;

const InstallButton = ({ children }) => {
  return (
    <ALink href="https://chrome.google.com/webstore/detail/astraliker-beta/cfdlkhibfeannofofnfbheelilicllpm">
      <ButtonIcon/>
      <ButtonText>
        {children}
      </ButtonText>
    </ALink>
  );
};

export default InstallButton;
