import { css } from 'styled-components';
import theme from './theme';

const sizes = theme.responsiveSizes;

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

export const customMedia = (size) => {
  return (...args) => css`
    @media (max-width: ${size}px) {
      ${css(...args)};
    }
  `
}
