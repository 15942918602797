import React from 'react';
import styled from 'styled-components';

import { media } from '../style/style-utils';
import { H2 } from './Headings';
import InstallButton from '../components/InstallButton';

const Wrapper = styled.div`
  max-width: ${props => props.theme.contentWidth};
  padding: 250px 15px;
  margin: auto;
  text-align: center;

  ${media.phone`
    font-size: 1em;
    padding: 100px 15px;
  `}
`;

const InstallButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledH2 = styled(H2)`
  text-align: center;
`;

const StyledP = styled.p`
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 1.5em;
  padding: 25px 0px;
  color: ${props => props.theme.colors.darkGrey};

  ${media.tablet`
    margin: 0 0 20px 0;
    font-size: 1.2em;
  `}

  ${media.phone`
    font-size: 1em;
  `}
`;

const StyledBulletNumber = styled.span`
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1.5em;
  color: ${props => props.theme.colors.blue};
`;

const HeroSection = () => (
  <Wrapper>
    <StyledH2>Getting started</StyledH2>
    <StyledP>
      <StyledBulletNumber>1. </StyledBulletNumber>
      Install the chrome extension.
    </StyledP>

    <InstallButtonWrapper>
      <InstallButton>INSTALL FOR FREE</InstallButton>
    </InstallButtonWrapper>

    <StyledP>
      <StyledBulletNumber>2. </StyledBulletNumber>
      Click on the Astraliker icon on the top right of your browser.
    </StyledP>

    <StyledP>
      <StyledBulletNumber>3. </StyledBulletNumber>
      Once the app window is open, go to "Tags" and choose tags to like.
    </StyledP>

    <StyledP>
      <StyledBulletNumber>4. </StyledBulletNumber>
      That’s it. <b>Relax</b> while Astraliker likes posts for you.
    </StyledP>
  </Wrapper>
);

export default HeroSection;
